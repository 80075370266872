import { FC } from "react";

import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Flex, Grid, Text } from "@radix-ui/themes";

import { ContentSection } from "~/Layout/Sections/ContentSection";
import { Section } from "~/Layout/Sections/Section";
import { TryAgainButton } from "~/modules/Error/components/TryAgainButton";

import "./Error.scss";

export const Scope: FC = () => {
  return (
    <ContentSection>
      <Section>
        <Grid gapY="7" columns="auto" pb={{ initial: "8", sm: "0" }}>
          <Flex
            align="center"
            direction="column"
            style={{
              maxWidth: "70%",
              margin: "auto",
              justifyContent: "center",
            }}
            gapY="7"
          >
            <Text size="6" className="text-header">
              Ops! Noe gikk galt...
            </Text>

            <Text size="4" align="center">
              Det ser ut til at du ikke ga de nødvendige tillatelsene under
              autorisering av Strava. For å bli med i konkurransen må vi kunne
              hente ut aktivitetene dine fra Strava. Huk derfor av for "View
              data about your private activities". Du kan prøve på nytt ved å
              klikke på knappen under. Hvis du har spørsmål til behandlingen av
              data, sjekk ut{" "}
              <a href="/gdpr" className="error-link">
                GDPR-siden
              </a>{" "}
              vår.
            </Text>

            <TryAgainButton />

            <a href="/" className="error-link error-link--back">
              <ArrowLeftIcon className="error-icon" />
              <Text>Tilbake til forsiden</Text>
            </a>
          </Flex>
        </Grid>
      </Section>
    </ContentSection>
  );
};
