import { FC } from "react";

import { Flex } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";

import { PodiumItem } from "./PodiumItem";
import { usePodiumData } from "./usePodiumData.api";

import "./Podium.scss";

export const Podium: FC = () => {
  const { podiumData } = usePodiumData();

  return (
    <Section noPaddingBottom flexGrowRevert depth={1}>
      <Flex
        flexGrow="0"
        justify="center"
        align="end"
        className="podium-section"
        gap={"4"}
      >
        {podiumData.length > 0 &&
          podiumData.map((item) => (
            <PodiumItem
              key={item.position}
              position={item.position}
              name={item.name}
              distance={item.distance}
              amountRaised={item.amountRaised}
            />
          ))}
      </Flex>
    </Section>
  );
};
