import { ContentSection } from "~/Layout/Sections/ContentSection";
import { FeedbackHeader } from "~/modules/Dashboard/Header/FeedbackHeader";

import { Badges } from "./Badges/Badges";
import { ComputasTVAksjonen } from "./ComputasTVAksjonen/ComputasTVAksjonen";
import { Footer } from "./Footer/Footer";
import { Podium } from "./Podium/Podium";
import { ProgressBar } from "./ProgressBar/ProgressBar";

// import { TabTopListActivities } from "./TabTopListActivities/TabTopListActivities";

export const Dashboard = () => {
  return (
    <>
      <FeedbackHeader finalText={true} />
      <ContentSection>
        <ProgressBar />
        <Podium />
        {/* <TabTopListActivities /> */}
        <Badges />
        <ComputasTVAksjonen />
      </ContentSection>
      <Footer />
    </>
  );
};
