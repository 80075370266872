import { useEffect, useState } from "react";

import { Flex, Text } from "@radix-ui/themes";

interface Props {
  endValue: number;
  additionalPrimaryText?: string;
  secondaryText: string | number;
}

export const InfoBox = (props: Props) => {
  const { endValue, additionalPrimaryText = "", secondaryText } = props;

  const [displayedValue, setDisplayedValue] = useState(0);
  const duration = 1000;

  useEffect(() => {
    const increment = Math.ceil(endValue / (duration / 16));

    let currentDisplayValue = 0;

    const interval = setInterval(() => {
      currentDisplayValue += increment;
      if (currentDisplayValue >= endValue) {
        setDisplayedValue(endValue);
        clearInterval(interval);
      } else {
        setDisplayedValue(currentDisplayValue);
      }
    }, 16);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [endValue]);

  return (
    <Flex direction="column" justify="center" align="center">
      <Text
        as="p"
        size={{
          initial: "3",
          md: "4",
          xl: "6",
        }}
        weight="bold"
      >{`${displayedValue} ${additionalPrimaryText}`}</Text>
      <Text
        size={{
          initial: "3",
          md: "4",
          xl: "6",
        }}
      >
        {secondaryText}
      </Text>
    </Flex>
  );
};
