import { FC, ReactNode } from "react";

import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";

import "./Layout.scss";

type LayoutProps = {
  children: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Theme scaling="100%" className="Layout" hasBackground={false}>
      {children}
    </Theme>
  );
};
