import { useQuery } from "@tanstack/react-query";

import { PodiumResponse } from "./model/PodiumResponse.model";

export const usePodiumData = () => {
  const { data, isPending, error } = useQuery<PodiumResponse[]>({
    queryKey: ["Podium-data"],
    queryFn: () => fetch("api/podium-data").then((res) => res.json()),
  });

  return {
    podiumData: data ?? [],
    isPodiumDataPending: isPending,
    podiumDataError: error,
  };
};
