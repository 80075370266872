import { FC } from "react";

import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Flex, Grid, Text } from "@radix-ui/themes";

import { ContentSection } from "~/Layout/Sections/ContentSection";
import { Section } from "~/Layout/Sections/Section";

import "./GDPR.scss";

export const GDPR: FC = () => {
  return (
    <ContentSection>
      <Section>
        <Grid as="div" gapY="7" columns="auto">
          <a href="/" className="gdpr-link">
            <ArrowLeftIcon className="gdpr-icon" />
            <Text>Tilbake</Text>
          </a>
          <Flex
            as="div"
            align="start"
            direction="column"
            style={{ maxWidth: "70%", textAlign: "start", margin: "auto" }}
            gapY="4"
          >
            <Text size="6" className="text-header">
              Personvern og datahåndtering for Løp For Meg
            </Text>

            <Text size="4">
              Ved å autentisere deg med Strava på Løp For Meg, gir du oss
              tilgang til din Strava-profil og aktiviteter, inkludert privat
              innhold som ikke er offentlig delt. Data vi henter vil bli lagret
              i en sikker database. Vi lagrer kun følgende data: din
              brukerinformasjon (fullt navn og profilbilde) og informasjon om
              dine aktiviteter (tittel, starttid, varighet, distanse og
              høydemeter). Av denne informasjon vil kun ditt navn og
              profilbilde, i tillegg til dine aktiviteters tittel, starttid og
              distanse, være synlig på nettsiden.
            </Text>

            <Text size="6" mt="6" className="gdpr-header">
              Avregistrering
            </Text>

            <Text size="4">
              Du kan når som helst trekke deg fra konkurransen ved å fjerne
              tilgangen via Stravas innstillinger (Settings -{">"} My Apps -
              {">"} "Revoke Access"). Hvis du fjerner tilgangen underveis i
              konkurransen, vil din informasjon bli slettet fra databasen og du
              vil bli fjernet fra deltakerlisten.
            </Text>
            <Text size="4">
              Etter konkurransen vil informasjonen være synlig på nettsiden i 1
              måned. Ønsker du å bli fjernet etter denne perioden, kan du
              kontakte oss på e-post. For spørsmål om fjerning av data,
              databehandling eller andre henvendelser, vennligst send en e-post
              til: kawa@computas.com.
            </Text>
          </Flex>
        </Grid>
      </Section>
    </ContentSection>
  );
};
