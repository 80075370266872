import { FC, ReactNode } from "react";

import { Box } from "@radix-ui/themes";
import classnames from "classnames";

type SectionProps = {
  children: ReactNode;
  secondary?: boolean;
  noPaddingBottom?: boolean;
  flexGrowRevert?: boolean;
  depth?: 1 | 2;
};

export const Section: FC<SectionProps> = ({
  children,
  secondary,
  noPaddingBottom,
  flexGrowRevert,
  depth,
}) => {
  const boxClass = classnames({
    secondary,
    noPaddingBottom,
    flexGrowRevert,
  });

  const boxDepth1 = {
    px: { md: "2.5em", lg: "5em" },
  };
  const boxDepth2 = {
    px: { md: "4em", lg: "8em" },
  };

  const boxDepth = depth === 1 ? boxDepth1 : depth === 2 ? boxDepth2 : {};

  return (
    <Box className={boxClass}>
      <Box p="1em" {...boxDepth}>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
