import { useQuery } from "@tanstack/react-query";

import { BadgeReponse } from "~/modules/Dashboard/Badges/badge.model";

const emptyResult: BadgeReponse = {
  badgeId: 0,
  title: "",
  description: "",
  image: "",
};

export const useBadges = () => {
  const { data, isPending, error } = useQuery<BadgeReponse[]>({
    queryKey: ["badges"],
    queryFn: () => fetch("/api/badges").then((res) => res.json()),
  });

  const safeData = data ?? [emptyResult];

  return {
    badges: safeData,
    badgesPending: isPending,
    badgesError: error,
  };
};
