import { createFileRoute } from "@tanstack/react-router";

import { Footer } from "~/modules/Dashboard/Footer/Footer";
import { FeedbackHeader } from "~/modules/Dashboard/Header/FeedbackHeader";
import { Success } from "~/modules/Success/Success";

export const Route = createFileRoute("/success")({
  component: () => (
    <>
      <FeedbackHeader finalText={true} />
      <Success />
      <Footer />
    </>
  ),
});
