import { useQuery } from "@tanstack/react-query";

import {
  ProgressBarData,
  ProgressBarResponse,
} from "~/modules/Dashboard/ProgressBar/model/ProgressBarData.model";

const emptyResult: ProgressBarData = {
  totalKiloMeters: 0,
  totalContribution: 0,
  moneyCap: 1,
  numParticipants: 0,
  remainingDays: 0,
  percentage: 0,
};

export const useProgressBarData = () => {
  const { data, isPending, error } = useQuery<ProgressBarResponse>({
    queryKey: ["progressBarData"],
    queryFn: () => fetch("/api/progress-bar-data").then((res) => res.json()),
  });

  const safeData = data ?? emptyResult;

  const percentage = data
    ? (data?.totalContribution / data?.moneyCap) * 100
    : 0;

  const totalKiloMeters = Math.round(data?.totalKiloMeters ?? 0);

  const newData: ProgressBarData = { ...safeData, percentage, totalKiloMeters };

  return {
    progressBarData: newData,
    progressBarDataPending: isPending,
    progressBarDataError: error,
  };
};
