export const getTransitionDuration = (percentage: number): number => {
  switch (true) {
    case percentage < 25:
      return 1900;
    case percentage < 50:
      return 3300;
    case percentage < 75:
      return 3700;
    default:
      return 4100;
  }
};
