import type { FC } from "react";

import { Text } from "@radix-ui/themes";
import { Flex } from "@radix-ui/themes/src/index.js";

import { Section } from "~/Layout/Sections/Section";

import "./Header.scss";

interface FeedbackHeaderProps {
  finalText: Boolean;
}

export const FeedbackHeader: FC<FeedbackHeaderProps> = ({ finalText }) => {
  return (
    <Section>
      <Flex
        direction={{ initial: "column", md: "row" }}
        gapX="9"
        gapY={{ initial: "5", md: "8" }}
        mt="5"
        mb="5"
        justify="between"
        align="center"
      >
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
          gapY={{ initial: "4", md: "8" }}
        >
          <img
            src="/logoer/barnekreftforeningen.png"
            alt="Barnkreftforeningen Logo"
            className="barnekreftlogo"
          />
          <a href="/" className="header-link header-link--back">
            <Text
              as="p"
              weight="bold"
              size="8"
              align="left"
              className="title"
              ml={{ initial: "0", md: "2" }}
            >
              LØP FOR MEG
            </Text>
          </a>
        </Flex>
        <Flex
          direction={{ initial: "column", md: "row" }}
          gap={{ initial: "2" }}
          align={{ initial: "center", md: "end" }}
        >
          <img
            src="/logoer/computasxdagenatifi.svg"
            alt="Computas Logo og Dagen at ifi"
            className="computaslogo"
          />
        </Flex>
      </Flex>
      {finalText && (
        <Flex pr="6" pl="6" pt="3">
          <Text as="p" size="4" align="center">
            Nå er Løp for meg over, takk for at du løp for en god sak! Computas
            har donert i overkant av 200.000,- kroner til Barnekreftforeningen,
            hvor Dagen@ifi og Computas samlet inn{" "}
            <Text weight="bold">16.182</Text>,- gjennom Løp for meg. <br />{" "}
            <br />
            Hele <Text weight="bold">93</Text> deltakere løp eller gikk for
            TV-aksjonen 🏃‍♀️ 💫. Vi ønsker å takke alle sammen for innsatsen!
          </Text>
        </Flex>
      )}
    </Section>
  );
};
