import { Button } from "@radix-ui/themes";

import "~/modules/Error/Error.scss";

export const TryAgainButton = () => {
  return (
    <Button
      size="4"
      onClick={() => (window.location.href = "/api/redirect-to-strava-auth")}
      className="error-button"
    >
      Prøv igjen
    </Button>
  );
};
