import { FC } from "react";

import { Flex } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";

import { FooterLink } from "./FooterLink";

export const Footer: FC = () => {
  return (
    <Section>
      <Flex
        justify="between"
        direction={{ initial: "column", sm: "row" }}
        gap={{ initial: "5", sm: "8" }}
      >
        <FooterLink
          link="https://www.barnekreftforeningen.no/lop-for-meg"
          linkText="Les mer om Løp for meg"
        />
        <FooterLink
          link="https://computas.com/jobb/"
          linkText="Lær mer om Computas!"
        />
        <FooterLink link="/gdpr" linkText="Personvern og avregistrering" />
      </Flex>
    </Section>
  );
};
