import { Flex } from "@radix-ui/themes";

import { ProgressBarData } from "~/modules/Dashboard/ProgressBar/model/ProgressBarData.model";

import { InfoBox } from "./InfoBox";

interface Props {
  progressBarData: ProgressBarData;
}

export const InfoBoxSection = (props: Props) => {
  const {
    progressBarData: { numParticipants, totalKiloMeters, remainingDays },
  } = props;

  return (
    <Flex
      justify="between"
      align="center"
      mx={{ initial: "2em", md: "4em" }}
      my="0.75em"
    >
      <InfoBox endValue={numParticipants} secondaryText="deltakere" />
      <InfoBox
        endValue={totalKiloMeters}
        additionalPrimaryText="km"
        secondaryText="løpt"
      />
      <InfoBox
        endValue={remainingDays}
        additionalPrimaryText="dager"
        secondaryText="igjen"
      />
    </Flex>
  );
};
