import type { FC } from "react";

import { Flex, Text } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";

import "./ComputasTVAksjonen.scss";

export const ComputasTVAksjonen: FC = () => {
  return (
    <Section depth={2}>
      <Flex
        gapX="9"
        gapY="5"
        direction="column"
        p="5"
        my="5"
        className="computas_tvaksjonen"
      >
        <Text size="6" className="text-header">
          Computas og TV-aksjonen
        </Text>
        <Text size="4">
          Computas har gjennom flere år vært fast bidragsyter i TV-aksjonen. Vi
          har alltid hatt engasjerte ansatte og personlig brenner vi gjerne for
          forskjellige saker. Det handler mye om å skape et reelt engasjement
          internt, og med å støtte TV-aksjonen får vi bidra i et bredt spekter
          av gode saker. TV-aksjonen er Norges største dugnad og det matcher en
          av grunnverdiene våre i Computas – nemlig samarbeid.
        </Text>
      </Flex>
    </Section>
  );
};
