import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Text } from "@radix-ui/themes";

import "./FooterLink.scss";

interface Props {
  link: string;
  linkText: string;
}

export const FooterLink = (props: Props) => {
  const { link, linkText } = props;

  return (
    <a href={link} className="footer-link">
      <Text>{linkText}</Text>
      <ArrowRightIcon className="footer-icon" />
    </a>
  );
};
