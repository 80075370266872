import { createFileRoute } from "@tanstack/react-router";

import { Footer } from "~/modules/Dashboard/Footer/Footer";
import { FeedbackHeader } from "~/modules/Dashboard/Header/FeedbackHeader";
import { GDPR } from "~/modules/GDPR/GDPR";

export const Route = createFileRoute("/gdpr")({
  component: () => (
    <>
      <FeedbackHeader finalText={true} />
      <GDPR />
      <Footer />
    </>
  ),
});
