import React, { useState } from "react";

import { Flex, Text } from "@radix-ui/themes";
import classNames from "classnames";

interface PodiumItemProps {
  position: number;
  name: string;
  distance: number;
  amountRaised: number;
}

export const PodiumItem: React.FC<PodiumItemProps> = ({
  position,
  name,
  distance,
  amountRaised,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  setTimeout(() => {
    setIsVisible(true);
  }, position * 200);

  return (
    <Flex
      direction={"column"}
      key={position}
      className={classNames("podium-item", `position-${position}`, {
        "build-up": isVisible,
      })}
      width={{
        initial: "30%",
        xs: "25%",
        sm: "13%",
      }}
      minWidth={{
        initial: "70px",
        xs: "90px",
        sm: "120px",
      }}
      gap="2"
    >
      <Text
        className="podium-name"
        size={{
          initial: "2",
          md: "4",
          xl: "5",
        }}
      >
        {name}
      </Text>
      <Flex
        direction={"column"}
        overflow={"hidden"}
        align={"center"}
        className={classNames("podium-box", "podium-box-" + position)}
      >
        <Flex
          className="podium-position-circle"
          mb={"1em"}
          position={"relative"}
          top={"0.5em"}
          align={"center"}
          justify={"center"}
        >
          <Text
            size={{
              initial: "3",
              md: "4",
              xl: "5",
            }}
            weight="bold"
          >
            {position}
          </Text>
        </Flex>

        <Text
          className="podium-distance"
          size={{
            initial: "3",
            md: "4",
            xl: "5",
          }}
        >
          {distance.toLocaleString("no-NO")} km
        </Text>
        <Text
          className="podium-amount"
          size={{
            initial: "3",
            md: "4",
            xl: "5",
          }}
        >
          {amountRaised.toLocaleString("no-NO", {
            style: "currency",
            currency: "NOK",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
