import { useEffect, useState } from "react";

import * as Progress from "@radix-ui/react-progress";
import { Box, Flex } from "@radix-ui/themes";

import { Section } from "~/Layout/Sections/Section";

import { InfoBoxSection } from "./components/InfoboxSection";
import { Markers } from "./components/Markers";
import { useProgressBarData } from "./hooks/useProgressBarData.api";
import { getTransitionDuration } from "./utils/progressBarUtils";

import "./styles/ProgressBar.scss";

export const ProgressBar = () => {
  const { progressBarData } = useProgressBarData();
  const [isGifFrozen, setIsGifFrozen] = useState(false);

  useEffect(() => {
    const duration = getTransitionDuration(progressBarData.percentage);

    const timeoutId = setTimeout(() => {
      setIsGifFrozen(true);
    }, duration);

    return () => clearTimeout(timeoutId);
  }, [progressBarData.percentage]);

  return (
    <Section depth={1}>
      <Flex direction="column" mt="5">
        <Markers progressBarData={progressBarData} />

        <Progress.Root
          className="progress-root"
          value={progressBarData.totalContribution}
          max={progressBarData.moneyCap}
        >
          <Progress.Indicator
            className={`progress-indicator`}
            style={{
              width: `${progressBarData.percentage}%`,
              transition: `width ${getTransitionDuration(progressBarData.percentage) / 1000}s ease`,
            }}
          >
            {progressBarData.percentage > 4 && (
              <Box className="gif-container">
                <img
                  src={
                    isGifFrozen
                      ? "/icon/lopikonstopped.gif"
                      : "/icon/lopeikonalt.gif"
                  }
                  className="lopeikon"
                  alt={isGifFrozen ? "Frozen icon" : "Running icon"}
                />
              </Box>
            )}
          </Progress.Indicator>
        </Progress.Root>
      </Flex>

      <InfoBoxSection progressBarData={progressBarData} />
    </Section>
  );
};
