import { FC } from "react";

import { Button, Flex, Grid, Text } from "@radix-ui/themes";

import { ContentSection } from "~/Layout/Sections/ContentSection";
import { Section } from "~/Layout/Sections/Section";

export const Success: FC = () => {
  return (
    <ContentSection>
      <Section>
        <Grid gapY="7" columns="auto" pb={{ initial: "8", sm: "0" }}>
          <Flex
            align="center"
            direction="column"
            m={"auto"}
            justify={"center"}
            maxWidth={"70%"}
            gapY="7"
          >
            <Text size="6">Du er med! 🎉</Text>

            <Text size="4" align="center">
              Du er nå registert. Husk å logge økter i Strava for å delta, og
              besøk dashboardet for å se hvor mye du og dine medstudenter har
              samlet inn! Kanskje du har oppnådd noen badges og? 🏃‍♀️ 💫
            </Text>

            <Button
              size="4"
              onClick={() => (window.location.href = "/")}
              style={{ cursor: "pointer" }}
            >
              Tilbake til forsiden
            </Button>
          </Flex>
        </Grid>
      </Section>
    </ContentSection>
  );
};
