import { useEffect, useState } from "react";

import { Box, Flex, Text } from "@radix-ui/themes";
import "@radix-ui/themes";

import { ProgressBarData } from "~/modules/Dashboard/ProgressBar/model/ProgressBarData.model";

interface Props {
  progressBarData: ProgressBarData;
}

interface MarkerProps {
  amount: number;
  startText?: string;
  endText?: string;
  shouldIncrement?: boolean;
}

const Marker = (props: MarkerProps) => {
  const { amount, shouldIncrement = false, startText, endText } = props;
  const [displayedValue, setDisplayedValue] = useState(0);
  const duration = 1000;

  useEffect(() => {
    if (!shouldIncrement) return;

    const increment = Math.ceil(amount / (duration / 20));

    let currentDisplayValue = 0;

    const interval = setInterval(() => {
      currentDisplayValue += increment;
      if (currentDisplayValue >= amount) {
        setDisplayedValue(amount);
        clearInterval(interval);
      } else {
        setDisplayedValue(currentDisplayValue);
      }
    }, 16);
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [amount, shouldIncrement]);

  return (
    <Box>
      {startText && (
        <Text
          as="span"
          size={{
            initial: "4",
            md: "6",
            xl: "6",
          }}
          mr="0.35em"
        >
          {startText}
        </Text>
      )}
      <Text
        as="span"
        wrap={"nowrap"}
        size={{
          initial: "4",
          md: "6",
          xl: "6",
        }}
        weight={"bold"}
      >
        {(shouldIncrement ? displayedValue : amount).toLocaleString("no-nb")} kr
      </Text>
      {endText && (
        <Text
          as="span"
          size={{
            initial: "4",
            md: "6",
            xl: "6",
          }}
          ml="0.35em"
        >
          {endText}
        </Text>
      )}
    </Box>
  );
};

export const Markers = (props: Props) => {
  const {
    progressBarData: { totalContribution, moneyCap },
  } = props;

  return (
    <Flex justify="between" mb="1em">
      <Marker amount={totalContribution} shouldIncrement endText="innsamlet" />

      <Marker amount={moneyCap} startText="Mål" />
    </Flex>
  );
};
