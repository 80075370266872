import { FC, ReactNode } from "react";

import { Flex } from "@radix-ui/themes";

type ContentSectionProps = {
  children: ReactNode;
};

export const ContentSection: FC<ContentSectionProps> = ({ children }) => {
  return <Flex flexGrow="1">{children}</Flex>;
};
