import { createFileRoute } from "@tanstack/react-router";

import { Footer } from "~/modules/Dashboard/Footer/Footer";
import { FeedbackHeader } from "~/modules/Dashboard/Header/FeedbackHeader";
import { Scope } from "~/modules/Error/Scope";

export const Route = createFileRoute("/scope")({
  component: () => (
    <>
      <FeedbackHeader finalText={false} />
      <Scope />
      <Footer />
    </>
  ),
});
