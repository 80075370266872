import { FC } from "react";

import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Flex, Grid, Text } from "@radix-ui/themes";

import { ContentSection } from "~/Layout/Sections/ContentSection";
import { Section } from "~/Layout/Sections/Section";

import "./Error.scss";

export const Error: FC = () => {
  return (
    <ContentSection>
      <Section>
        <Grid gapY="7" columns="auto" pb={{ initial: "8", sm: "0" }}>
          <Flex
            align="center"
            direction="column"
            style={{
              maxWidth: "70%",
              margin: "auto",
              justifyContent: "center",
            }}
            gapY="7"
          >
            <Text size="6" className="text-header">
              Ops! Noe gikk galt...
            </Text>

            <Text size="4" align="center">
              Det ser ut til at noe feilet når vi koblet brukeren din opp mot
              Strava. Dette kan oppstå ved uthenting av et access token eller
              andre lignende hendelser.
            </Text>

            {/* <TryAgainButton /> */}

            <a href="/" className="error-link error-link--back">
              <ArrowLeftIcon className="error-icon" />
              <Text>Tilbake til forsiden</Text>
            </a>
          </Flex>
        </Grid>
      </Section>
    </ContentSection>
  );
};
